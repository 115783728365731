<template>
  <v-app style="height: 100vh">
    <v-container class="my-12">
      <v-row>
        <v-col>
          <v-alert type="info" text>
            <h1 class="text-h3 font-weight-bolder">Información académica</h1>
            <p class="text-body-1">
              Para conocer tu información académica escribe tu <span class="font-weight-bolder">código de estudiante</span> válido o tus <span class="font-weight-bolder">nombres</span> y <span class="font-weight-bolder">apellidos</span> correctamente. Puedes intentarlo usando mayúsculas o minúsculas.
            </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            dense
            hide-details
            prepend-icon=""
            prepend-inner-icon="mdi-magnify"
            placeholder="Escribe tu código de estudiante o tus nombres o apellidos"
            v-model="search"
            block
          ></v-text-field>
          <v-btn
            elevation="0"
            class="mt-3"
            color="primary"
            :loading="isLoading"
            @click="searchAcademicInformation"
            >Consultar información</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="search != '' && submitted == true">
        <v-col>
          <v-sheet
            rounded="xl"
            color="red lighten-5"
            elevation="0"
            class="mx-auto py-5 px-7 my-5"
            v-if="Object.keys(studentInformation).length === 0"
          >
            <h1 class="red--text font-weight-bolder mb-4">
              Información no encontrada
            </h1>
            <p class="mb-0 text-body-1">
              Asegúrate de escribir un <span class="font-weight-bolder">código de estudiante</span> válido o tus <span class="font-weight-bolder">nombres</span> y <span class="font-weight-bolder">apellidos</span> correctamente. Puedes intentarlo usando mayúsculas o minúsculas.
            </p>
          </v-sheet>
          <v-sheet
            rounded="xl"
            color="teal lighten-5"
            elevation="0"
            class="mx-auto py-5 px-7 my-5"
            v-else
          >
            <h1 class="teal--text font-weight-bolder mb-4">
              Información encontrada
            </h1>
            <p>
              Nombres:
              <span class="font-weight-bolder">{{
                studentInformation.first_name
              }}</span>
            </p>
            <p>
              Apellidos:
              <span class="font-weight-bolder">{{
                studentInformation.last_name
              }}</span>
            </p>
            <p>
              Código:
              <span class="font-weight-bolder">{{
                studentInformation.code
              }}</span>
            </p>
            <p>
              Correo:
              <span class="font-weight-bolder">{{
                studentInformation.email
              }}</span>
            </p>
            <p>
              Grado:
              <span class="font-weight-bolder">{{
                studentInformation.grade_section_group?.grades?.name
              }}</span>
            </p>
            <p>
              Sección académica:
              <span class="font-weight-bolder">{{
                `${studentInformation.grade_section_group?.section_group?.section?.name}-${studentInformation.grade_section_group?.section_group?.group?.name}`
              }}</span>
            </p>
            <p>
              Especialidad:
              <span class="font-weight-bolder">{{
                `${studentInformation.grade_speciality_group?.speciality?.name} ${studentInformation.grade_speciality_group?.groups?.name}`
              }}</span>
            </p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";
export default {
  data() {
    return {
      search: "",
      studentInformation: {},
      submitted: false,
      isLoading: false,
    };
  },
  methods: {
    searchAcademicInformation() {
      this.isLoading = true;
      studentRepository
        .getStudentAcademicInfoOnTypedMatch(this.search)
        .then(({ data }) => {
          this.submitted = true;
          this.studentInformation = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>