import GeStudentApiService from "@/core/services/geStudentApi.service";
import Axios from "axios";

/**
 * GET request to fetch data by subject id (gets all evaluations)
 * @param subject_teacher_id
 * @returns {*}
 */
export const getStudentInfo = (subject_teacher_id) => {
  return GeStudentApiService.get("evaluations/subject-teachers", subject_teacher_id);
};

/**
 * GET request to fetch all academic information given a typed match
 * code
 * name
 * @returns {*}
 */
export const getStudentAcademicInfoOnTypedMatch = (searchTerm) => {
  let url;
  if (process.env.GE_ENV === "production") {
    url = process.env.GE_API_URL;
  } else if (process.env.GE_ENV === "development") {
    url = process.env.GE_DEV_API_URL;
  } else {
    url = process.env.GE_LOCAL_API_URL;
  }
  return Axios.get(`${url}/students/search/academic-group?query=${searchTerm}`);
};

export default {
  getStudentAcademicInfoOnTypedMatch,
};
