import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/student-academic-information",
      name: "student-academic-information",
      component: () => import("@/view/pages/student-information-lookup"),
    },
    {
      path: "/",
      redirect: "dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/file",
          name: "file",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              name: "online-payments",
              path: "/online-payments",
              component: () => import("@/view/pages/online_payments"),
            },
            {
              path: "/view_subjects_modules",
              name: "view_subjects_modules",
              component: () =>
                import("@/view/pages/student_files/ViewSubjects_Modules.vue"),
            },
            {
              path: "/student_scores",
              name: "student_scores",
              component: () =>
                import("@/view/pages/student_files/StudentScores.vue"),
              props(route) {
                return route.query || {};
              },
            },

            {
              path: "/evaluation_summary",
              name: "evaluation_summary",
              component: () =>
                import("@/view/pages/student_files/EvaluationSummary.vue"),
            },

            {
              path: "/contact_numbers",
              name: "contact_numbers",
              component: () => import("@/view/pages/contact"),
            },
          ],
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue"),
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue"),
            },
          ],
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue"),
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue"),
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue"),
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue"),
            },
            {
              path: "student_profile",
              name: "student_profile",
              component: () =>
                import("@/view/pages/profile/StudentProfile.vue"),
            },
            // {
            //   path: "downloads",
            //   name: "downloads",
            //   component: () => import("@/view/pages/reports"),
            // },
            // {
            //   path: "score_sheet",
            //   name: "score_sheet",
            //   component: () => import("@/view/pages/reports/scoreSheet.vue"),
            // },
            {
              path: "student_file",
              name: "student_file",
              component: () => import("@/view/pages/reports/studentFile.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-5.vue"),
    },
  ],
});
